<template>
  <div class="confirmOrder">
    <!-- 头部 -->
    <div class="confirmOrder-header">
      <div class="header-content">
        <p>
          <i class="el-icon-s-order"></i>
        </p>
        <p>确认订单</p>
        <router-link to></router-link>
      </div>
    </div>
    <!-- 头部END -->

    <!-- 主要内容容器 -->
    <div class="content" v-loading="loading">
      <!-- 选择地址 -->
      <div class="section-address">
        <div class="head">
          <h3 class="title">收货地址</h3>
          <div class="add" @click="addAddress">
            <i class="el-icon-circle-plus-outline"></i>添加新地址
          </div>
        </div>

        <div class="address-body">         
          <ul :class="['consignee-list', { 'switch-off': consigneeOff }]">
            <li
              v-for="item in addresses"
              :key="item.AddressId"
              class="consignee-li"
              @click="checkAddress(item.AddressId)"
            >
              <div
                :class="[
                  'consignee-item',
                  { 'item-selected': item.AddressId == confirmAddress },
                ]"
              >
                <span limit="6" :title="item.Name"
                  >{{ item.Name }} {{ item.Province }} <b></b
                ></span>
              </div>
              <div :class="'addr-detail'">
                <span class="addr-name" limit="6">{{ item.Name }}</span>
                <span class="addr-info" limit="45">
                  {{ item.Province }} {{ item.City }} {{ item.District }}
                  {{ item.Town }} {{ item.Address }}</span
                >
                <span class="addr-tel">{{
                  item.PhoneNumber.replace(
                    $regular.addressPhone,
                    $regular.adderssPhoneText
                  )
                }}</span>
                <span class="addr-default" v-if="item.IsDefault">默认地址</span>
              </div>
            </li>
          </ul>
          <div
            :class="[
              'addr-switch',
              { 'switch-on': !consigneeOff, 'switch-off': consigneeOff },
            ]"
           
          >
            <span style="cursor:pointer;"  @click="consigneeClick">{{ consigneeOff ? "收起地址" : "更多地址" }}<b></b></span>
            
          </div>
        </div>
      </div>
      <!-- 选择地址END -->

      <!-- 商品及优惠券 -->
      <div class="section-goods">
        <div class="order-head">
          <h3 class="title">送货清单</h3>
          <el-popover
            placement="top-start"
            title=""
            width="200"
            trigger="hover"
            content="因可能存在系统缓存、页面更新导致价格变动异常等不确定性情况出现，商品售价以本结算页商品价格为准。"
          >
            <div slot="reference" class="price-desc">
              <i class="el-icon-info"><span>价格说明</span></i>
            </div>
          </el-popover>
        </div>

        <div class="goods-list">
          <el-row
            class="order-list"
            v-for="order in orderList"
            :key="order.type"
          >
            <el-col :span="8" class="order-shipment">
              <div style="margin: 10px 10px">
                <h4 class="title">配送方式</h4>
                <p class="shipment">
                  快递运输<span v-if="order.type == 0">（满100包邮）</span>
                </p>
              </div>
            </el-col>
            <el-col :span="16" class="goods">
              <h4 style="margin: 10px 10px; color: #666">
                <span :class="['title', { isjd: order.type == 1 }]">{{
                  order.type == 0 ? "严选" : "智选"
                }}</span>
              </h4>
              <el-row
                v-for="item in order.goodsList"
                :key="item.id"
                :class="['item', { blur: item.Inventory < item.num }]"
              >
                <el-col :span="14">
                  <img
                    src="../assets/imgs/imgError.jpeg"
                    v-real-img="$target + item.productImg"
                    class="goodimg"
                  />
                  <router-link
                :to="{
                  path: '/goods/info',
                  query: { productID: item.productID },
                }"
                title="点击查看商品详情"
                target="_blank"
              >
                  <span class="pro-name">{{ item.productName}}<span class="disc">{{ item.skuName }}</span></span>
                </router-link>
                  <br/><span class="disc" style="padding-left:0">商品编码：{{ item.skuId }}</span>
                  </el-col>
                <el-col :span="4" style="text-align: center">
                  <span class="pro-price"
                    >{{ item.price.toFixed(2) + "" + $currency }}
                  </span></el-col
                >
                <el-col :span="4" style="text-align: center"
                  >x {{ item.num }}</el-col
                >
                <el-col :span="2">{{
                  item.Inventory
                    ? item.Inventory >= item.num
                      ? "有货"
                      : item.Inventory == 0
                      ? "--"
                      : "无货"
                    : "无货"
                }}</el-col>
              </el-row>
              <div class="order-price">
                <span
                  >小计：<span class="sumprice">{{
                    order.sumPrice.toFixed(2)
                  }}</span>
                </span>
                <span style="margin-left: 50px"
                  >运费：{{
                    order.freight == -1 || inventoryErr
                      ? "--"
                      : order.freight.toFixed(2)
                  }}</span
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 商品及优惠券END -->

      <!-- 配送方式 -->
      <!-- <div class="section-shipment">
        <div style="margin: 10px 10px">
          <p class="title">配送方式</p>
          <p class="shipment">快递运输（满100包邮）</p>
        </div>
      </div> -->
      <!-- 配送方式END -->

      <!-- 发票 -->
      <!-- <div class="section-invoice">
        <p class="title">发票</p>
        <p class="invoice">电子发票</p>
        <p class="invoice">个人</p>
        <p class="invoice">商品明细</p>
      </div> -->
      <!-- 发票END -->

      <!-- 结算列表 -->
      <div class="section-count">
        <div class="money-box">
          <ul>
            <li>
              <span class="title">商品件数：</span>
              <span class="value">{{ checkNum }}件</span>
            </li>
            <li>
              <span class="title">商品总价：</span>
              <span class="value">{{
                totalPrice.toFixed(2) + "" + $currency
              }}</span>
            </li>
            <li>
              <span class="title">运费：</span>
              <span class="value">{{
                expressFee > 0
                  ? expressFee.toFixed(2) + "" + $currency
                  : inventoryErr || expressFee == -1
                  ? "--"
                  : "包邮"
              }}</span>
            </li>
            <li class="total">
              <span class="title">应付总额：</span>
              <span class="value">
                <span class="total-price">{{
                  (totalPrice + expressFee).toFixed(2) + "" + $currency
                }}</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 结算列表END -->

      <!-- 结算导航 -->
      <div class="section-bar">
        <div class="btn">
          <router-link to="/shoppingCart" class="btn-base btn-return"
            >返回购物车</router-link
          >
          <a
            v-if="!isPay"
            href="javascript:void(0);"
            @click="addOrder"
            class="btn-base btn-primary"
            >提交订单</a
          >
        </div>
      </div>
      <!-- 结算导航END -->
    </div>
    <!-- 主要内容容器END -->
    <!-- 添加/修改收货地址 -->
    <address-editer
      :hidden.sync="hiddenDialog"
      :address="address"
      @change="addressConfirm"
    ></address-editer>
    <payIndex
      :visible.sync="visible"
      :id="clickOrderId"
      :routerReturn="true"
      @delete="deleteCart"
    ></payIndex>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import addressEditer from "@/components/user/editer/address-editer.vue";
import payIndex from "@/views/order/pay/index";
export default {
  components: {
    addressEditer,
    payIndex,
  },
  name: "",
  data() {
    return {
      // 虚拟数据
      confirmAddress: 0, // 选择的地址id
      // 地址列表
      addresses: [],
      hiddenDialog: true,
      address: {},
      expressFee: 0,
      loading: false,
      visible: false,
      clickOrderId: "",
      products: {},
      checkNum: 0,
      totalPrice: 0,
      isPay: false,
      consigneeOff: false,
      orderList: [],
      inventoryErr: false,
      isClickAddOrder: true,
    };
  },
  watch: {
    async confirmAddress(e) {
      //选中地址置顶
      if (e > 0) {
        const mo = this.addresses.find((item) => item.AddressId == e);
        var list = [];
        this.addresses.forEach((item) => {
          if (item.AddressId != e) {
            list.push(item);
          }
        });
        list.unshift(mo);
        this.addresses = list;
        this.consigneeOff = false;
        // this.selectedAddresses=this.addresses.find(item=>item.AddressId==e)
        //商品运费与库存查询
        let expressFee = 0;
        let sku = [];
        for (const item of this.products) {
          sku.push({ skuId: item.skuId, num: item.num });
        }
        this.loading = true;
        await this.$axios
          .post("/api/GoodsOrder/GetSKUData", {
            addressId: e,
            sku: sku,
          })
          .then((res) => {
            const data = res.data;
            for (let item of this.orderList) {
              const mo = data.find((ite) => ite.SourceId == item.type);
              if (mo) {
                const PostFee = mo.PostFee;
                expressFee += PostFee;
                item.freight = PostFee;
                for (const sku of mo.Sku) {
                  let goods = item.goodsList.find((a) => a.skuId == sku.SkuId);
                  if (goods) {
                    goods.Inventory = sku.Inventory;
                  }
                }
              }
            }
          })
          .catch((err) => {
            this.inventoryErr = true;
            this.loading = false;
            return Promise.reject(err);
          });
        this.loading = false;
        this.expressFee = expressFee;
        this.isClickAddOrder = true;
        this.orderList.forEach((item) => {
          if (item.goodsList.some((ite) => ite.Inventory <= 0)) {
            this.isClickAddOrder = false;
            return;
          }
        });
      }
    },
    getTotalPrice(e) {
      this.totalPrice = e;
    },
  },
  activated() {
    this.loading = false;
    this.isPay = false;
    this.expressFee = 0;
    this.confirmAddress = 0;
    this.totalPrice = 0;
    // 如果没有勾选购物车商品直接进入确认订单页面,提示信息并返回购物车
    if (this.getCheckNum < 1) {
      this.notifyInfo("请勾选商品后再结算", "温馨提示");
      this.$router.push({ path: "/shoppingCart" });
    } else {
      this.orderList = [];
      const products = this.getCheckGoods;
      this.products = products;
      const list = products.filter((item) => item.goodsFromType == 0);
      if (list.length > 0) {
        const sumPrice = this.sumPrice(list);
        this.orderList.push({
          goodsList: list,
          type: 0,
          sumPrice: sumPrice,
          freight: -1,
        });
      }
      const list1 = products.filter((item) => item.goodsFromType == 1);
      if (list1.length > 0) {
        const sumPrice = this.sumPrice(list1);
        this.orderList.push({
          goodsList: list1,
          type: 1,
          sumPrice: sumPrice,
          freight: -1,
        });
      }
      this.checkNum = this.getCheckNum;
      this.totalPrice = this.getTotalPrice;
      this.findAllAddress();
    }
  },
  computed: {
    // 结算的商品数量; 结算商品总计; 结算商品信息
    ...mapGetters(["getCheckNum", "getTotalPrice", "getCheckGoods"]),
    sumPrice() {
      return (data) => {
        let number = 0;
        data.forEach((item) => {
          number += item.price * item.num;
        });
        return parseFloat(number.toFixed(2));
      };
    },
  },
  methods: {
    ...mapActions(["deleteShoppingCart", "setOrderInfo"]),
    consigneeClick() {
      this.consigneeOff = !this.consigneeOff;
    },
    checkAddress(id) {
      this.confirmAddress = id;
    },
    addOrder() {
      if (this.confirmAddress == 0) {
        this.notifyError("请选择收货地址");
        return;
      }
      if (!this.isClickAddOrder) {
        this.notifyError("订单库存不足，不可下单");
        return;
      }
      this.loading = true;
      let products = this.getCheckGoods;
      const cartIds = products.map((item) => {
        return item.id;
      });
      const query = {
        AddressId: this.confirmAddress,
        CartIds: cartIds,
        PayType: 0,
        PostFee: this.expressFee,
        Remark: "",
      };
      this.$axios
        .post("/api/GoodsOrder/Order_Confirm", this.qs.stringify(query))
        .then((res) => {
          //debugger;
          if (res.data && res.data.length > 0) {
            const orderID = res.data.map((item) => {
              return item.id;
            });
            // #region  上线订单审核功能后删除
            // this.isPay = true;
            // const createTime = new Date();
            // // 计算到期时间
            // const endTime = this.$util.getOrderEndTime(createTime);
            // const orderInfo = {
            //   createTime: createTime,
            //   payAmount: this.getTotalPrice + this.expressFee,
            //   id: orderID.toString(),
            //   endTime: endTime,
            //   status: 0,
            //   isDelte: false,
            // };
            // this.setOrderInfo(orderInfo);
            // // this.notifySucceed("订单支付成功");
            // this.visible = true;
            // this.clickOrderId = orderID.toString();
            // #endregion

            // #region  审核订单功能

            const status = res.data.find((item) => item.status == 11);
            if (status) {
              // this.$alert("您创建的订单存在需审核的订单，当前不可兑换，请前往订单列表查看，如有疑问请联系客服。。。", "订单创建成功", {
                this.$alert("订单已提交，<strong>正在审核中</strong>...", "订单未创建成功", {
                type: 'warning',
                dangerouslyUseHTMLString: true,
                confirmButtonText: "查看详情",
                closeOnClickModal: true,
                callback: (action, instance) => {
                  this.deleteCart();
                  this.$router.push({ path: "/order/index" });
                },
              });
            } else {
              this.isPay = true;
              const createTime = new Date();
              // 计算到期时间
              const endTime = this.$util.getOrderEndTime(createTime);
              const orderInfo = {
                createTime: createTime,
                payAmount: this.getTotalPrice + this.expressFee,
                id: orderID.toString(),
                endTime: endTime,
                status: 0,
                isDelte: false,
              };
              this.setOrderInfo(orderInfo);
              // this.notifySucceed("订单支付成功");
              this.visible = true;
              this.clickOrderId = orderID.toString();
            }
            //#endregion
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          return Promise.reject(err);
        });
    },
    deleteCart() {
      let products = this.getCheckGoods;
      for (let i = 0; i < products.length; i++) {
        const temp = products[i];
        // 删除已经结算的购物车商品
        this.deleteShoppingCart(temp.id);
      }
    },
    addressConfirm(e) {
      // console.log(e, "addressConfirm");
      this.$axios.post("/api/UserAddress/AddAddress", e).then((res) => {
        if (res.data) {
          this.addresses.push(res.data);
          this.hiddenDialog = true;
        }
      });
    },
    findAllAddress() {
      this.confirmAddress = [];
      this.addresses = [];
      this.loading = true;
      this.$axios.get("/api/UserAddress/GetListAddress", "").then((res) => {
        if (res.data.length > 0) {
          //去除不完整地址信息
          this.addresses=res.data.filter(it=>it.Province&&it.City&&it.District)
          if(this.addresses.length>0){
            //存在默认地址选中默认值，否则选中第一个地址
            const mode =this.addresses.find((item) => item.IsDefault);
            if (mode) {
              this.confirmAddress = mode.AddressId;
            } else {
              this.confirmAddress = this.addresses[0].AddressId;
            }
          }
          
        }
        this.loading = false;
      });
    },
    addAddress() {
      this.address = {
        Name: "",
        PhoneNumber: "",
        Province: "",
        City: "",
        District: "",
        Address: "",
        Tag: "",
        PostCode: "",
      };
      this.hiddenDialog = false;
    },
  },
};
</script>
<style scoped>
.confirmOrder {
  background-color: #f5f5f5;
  padding-bottom: 20px;
  min-height: calc(100vh - 408px);
}
/* 头部CSS */
.confirmOrder .confirmOrder-header {
  background-color: #fff;
  /* border-bottom: 2px solid #ff6701; */
  margin-bottom: 20px;
  border-bottom: 2px solid var(--ThemeColor,#ff6701);
}
.confirmOrder .confirmOrder-header .header-content {
  width: 1225px;
  margin: 0 auto;
  height: 80px;
}
.confirmOrder .confirmOrder-header .header-content p {
  float: left;
  font-size: 28px;
  line-height: 80px;
  color: #424242;
  margin-right: 20px;
}
.el-icon-s-order {
  font-size: 45px;
  /* color: #ff6701; */
  line-height: 80px;
  color:var(--ThemeColor,#ff6701);
}
/* 头部CSS END */

/* 主要内容容器CSS */
.confirmOrder .content {
  width: 1225px;
  margin: 0 auto;
  padding: 48px 0 0;
  background-color: #fff;
}

/* 选择地址CSS */
.confirmOrder .content .section-address .head {
  height: 40px;
}
.confirmOrder .content .section-address {
  margin: 0 48px;
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
}
.confirmOrder .content .section-address .head .title {
  color: #333;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;
  float: left;
}
.confirmOrder .content .section-address .head .add {
  float: right;
  cursor: pointer;
}
/* .confirmOrder .content .address-body li {
  float: left;
  color: #333;
  width: 220px;
  height: 178px;
  border: 1px solid #e0e0e0;

  margin-right: 17px;
  margin-bottom: 24px;
} */
.confirmOrder .content .address-body .address-info {
  padding: 15px 24px 0;
}
.confirmOrder .content .address-body .in-section {
  border: 1px solid #ff6700;
}
.confirmOrder .content .address-body li h2 {
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  margin-bottom: 10px;
}
.confirmOrder .content .address-body li p {
  font-size: 14px;
  color: #757575;
}
.confirmOrder .content .address-body li .address {
  padding: 10px 0;
  max-width: 180px;
  max-height: 88px;
  line-height: 22px;
  overflow: hidden;
}
.confirmOrder .content .address-body .add-address {
  text-align: center;
  line-height: 30px;
}
.confirmOrder .content .address-body .add-address i {
  font-size: 30px;
  padding-top: 50px;
  text-align: center;
}
.confirmOrder .content .address-body .in-section .superscript {
  position: absolute;
  width: 35px;
  height: 35px;
  overflow: hidden;
}
.confirmOrder .content .address-body .in-section .superscript .gradual {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #f2270c 50%, transparent 50%);
}
.confirmOrder .content .address-body .in-section .superscript .desc {
  position: absolute;
  top: 10%;
  z-index: 1;
  color: #ffffff;
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  font-size: 10px;
}
.address-body {
  margin-left: 40px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #909399;
}
.address-body .consignee-item:hover,
.address-body .consignee-item.item-selected {
  border: 2px solid var(--ThemeColor,#ff6701);
  padding: 4px 10px;
}
.address-body .consignee-item.item-selected {
  cursor:default;
}
.address-body .consignee-item {
  width: 120px;
  float: left;
  text-align: center;
  height: 18px;
  line-height: 18px;
  padding: 5px 10px;
  position: relative;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
}

.address-body .consignee-li:hover {
  background-color: #fff0f0;
}

.address-body li.consignee-li {
  list-style: none;
  height: 30px;
  margin: 6px 0;
  float: left;
  width: 99.8%;
  background-color: #fff;
}

.address-body .addr-detail {
  float: left;
  height: 30px;
  line-height: 30px;
  margin-left: 10px;
}
.address-body ul.consignee-list {
  width: 99.8%;
  overflow: hidden;
  height: 42px;
}

.address-body ul.consignee-list.switch-off {
  overflow: inherit;
}

.address-body .consignee-item.item-selected b {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0px;
  height: 0px;
  overflow: hidden;
  /* background: url(//misc.360buyimg.com/user/purchase/2.0.0/css/i/selected-icon.png)
    no-repeat; */
  margin: 0px auto;
  border-bottom: 12px solid var(--ThemeColor,#ff6701);
  border-left:  12px solid transparent;

}

.address-body .addr-detail .addr-default {
  display: inline-block;
  margin: 5px 10px;
  background-color: #999;
  color: #fff;
  padding: 0 3px;
  line-height: 20px;
}
.address-body .addr-switch b {
  display: inline-block;
  vertical-align: middle;
  height: 10px;
  line-height: 10px;
  width: 9px;
  margin-left: 5px;
  background: url(//misc.360buyimg.com/user/purchase/2.0.0/widget/consignee-scroll/i/addr-i.png)
    no-repeat 0 0;
}
.address-body .addr-switch.switch-on b {
  background: url(//misc.360buyimg.com/user/purchase/2.0.0/widget/consignee-scroll/i/addr-i.png)
    no-repeat 0 0;
}
.address-body .addr-switch.switch-off b {
  background: url(//misc.360buyimg.com/user/purchase/2.0.0/widget/consignee-scroll/i/addr-i.png)
    no-repeat 0 -10px;
}
.address-body .addr-detail .addr-tel {
  margin: 0px 10px;
}
/* 选择地址CSS END */

/* 商品及优惠券CSS */
.confirmOrder .content .section-goods {
  margin: 20px 48px;
}
.confirmOrder .content .section-goods p.title {
  color: #333;
  font-size: 18px;
  line-height: 40px;
}
.confirmOrder .content .section-goods .goods-list {
  padding: 5px 0;
  /* border-top: 1px solid #e0e0e0; */
  border-bottom: 1px solid #e0e0e0;
}
.confirmOrder .content .section-goods .goods-list li {
  padding: 10px 0;
  color: #424242;
  overflow: hidden;
}
.confirmOrder .content .section-goods .goods-list li .goodimg {
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.confirmOrder .content .section-goods .goods-list li .pro-name {
  float: left;
  width: 650px;
  line-height: 30px;
}

.confirmOrder .content .section-goods .goods-list li .pro-price {
  float: left;
  width: 150px;
  text-align: center;
  line-height: 30px;
}
.confirmOrder .content .section-goods .goods-list li .pro-status {
  float: left;
  width: 99px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.confirmOrder .content .section-goods .goods-list li .pro-total {
  float: left;
  width: 190px;
  text-align: center;
  color: #ff6700;
  line-height: 30px;
}
/* 订单样式 */
.confirmOrder .content .section-goods .order-list {
  background-color: #f5f5f5;
  margin-bottom: 10px;
  font-size: 14px;
}
.confirmOrder .content .section-goods .goods {
  background-color: #f3fbfe;
}
.confirmOrder .content .section-goods .goods .title {
  border-radius: 10px;
  background-color: #67c23a;
  color: #f5f5f5;
  padding: 0px 10px;
  font-size: 12px;
}
.confirmOrder .content .section-goods .goods .title.isjd {
  background-color: #ff5933;
}
.confirmOrder .content .section-goods .goods .item {
  margin: 10px 10px;
}
.confirmOrder .content .section-goods .goods .item.blur {
  /*Chrome, Opera*/
  /* -webkit-filter: blur(1px); */
  /* -moz-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px); */
  opacity: 0.5;
  /* background-color:#0000005f; */
}
.confirmOrder .content .section-goods .goods .item .goodimg {
  float: left;
  width: 80px;
  height: 80px;
  margin-right: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
}
.confirmOrder .content .section-goods .goods .item .pro-name {
  color: #666;
}
.confirmOrder .content .section-goods .goods .item .disc {
  font-size: 12px;
  color: #909399;
  padding-left: 10px;
}
.confirmOrder .content .section-goods .goods .item .pro-price {
  font-family: verdana;
  color: #ff6701;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: middle;
}
.confirmOrder .content .section-goods .goods .order-price {
  left: 10px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-weight: 700;
}
.confirmOrder .content .section-goods .goods .order-price .sumprice {
  color: #ff6700;
}
.order-shipment {
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}
.order-shipment .shipment {
  float: left;
  line-height: 38px;
  font-size: 14px;
  color: #ff6700;
}
.section-goods .order-head {
  line-height: 40px;
  height: 40px;
  color: #333;
  font-size: 14px;
  border: 10px 10px;
}
.section-goods .order-head .title {
  height: 40px;
  line-height: 40px;
  float: left;
}
.section-goods .order-head .price-desc {
  float: right;
  margin-right: 10px;
}
/* 商品及优惠券CSS END */

/* 配送方式CSS */
.confirmOrder .content .section-shipment {
  margin: 0 48px;
  padding: 25px 0;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}
.confirmOrder .content .section-shipment .title,
.order-shipment .title {
  float: left;
  width: 150px;
  color: #333;
  font-size: 14px;
  line-height: 38px;
}
.confirmOrder .content .section-shipment .shipment {
  float: left;
  line-height: 38px;
  font-size: 14px;
  color: #ff6700;
}
/* 配送方式CSS END */

/* 发票CSS */
.confirmOrder .content .section-invoice {
  margin: 0 48px;
  padding: 25px 0;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}
.confirmOrder .content .section-invoice .title {
  float: left;
  width: 150px;
  color: #333;
  font-size: 18px;
  line-height: 38px;
}
.confirmOrder .content .section-invoice .invoice {
  float: left;
  line-height: 38px;
  font-size: 14px;
  margin-right: 20px;
  color: #ff6700;
}
/* 发票CSS END */

/* 结算列表CSS */
.confirmOrder .content .section-count {
  margin: 0 48px;
  padding: 20px 0;
  overflow: hidden;
}
.confirmOrder .content .section-count .money-box {
  float: right;
  text-align: right;
}
.confirmOrder .content .section-count .money-box .title {
  float: left;
  width: 126px;
  height: 30px;
  display: block;
  line-height: 30px;
  color: #757575;
  font-size: inherit;
}
.confirmOrder .content .section-count .money-box .value {
  float: left;
  min-width: 105px;
  height: 30px;
  display: block;
  line-height: 30px;
  color: var(--ThemeColor,#ff6701);
}
.confirmOrder .content .section-count .money-box .total .title {
  padding-top: 15px;
}
.confirmOrder .content .section-count .money-box .total .value {
  padding-top: 10px;
}
.confirmOrder .content .section-count .money-box .total-price {
  font-size: 30px;
}
/* 结算列表CSS END */

/* 结算导航CSS */
.confirmOrder .content .section-bar {
  padding: 20px 48px;
  border-top: 2px solid #f5f5f5;
  overflow: hidden;
}
.confirmOrder .content .section-bar .btn {
  float: right;
}
.confirmOrder .content .section-bar .btn .btn-base {
  float: left;
  margin-left: 30px;
  width: 158px;
  height: 38px;
  border: 1px solid #b0b0b0;
  font-size: 14px;
  line-height: 38px;
  text-align: center;
}
.confirmOrder .content .section-bar .btn .btn-return {
  color: #757575;
  border-color: rgba(0, 0, 0, 0.27);
}
.confirmOrder .content .section-bar .btn .btn-primary {
  /* background: #ff6701;
  border-color: #ff6701; */
  color: #fff;
  border-color:var(--ThemeColor,#ff6701);
  background-color:var(--ThemeColor,#ff6701);
}
.confirmOrder .content .section-bar .btn .btn-primary:hover {
  text-decoration: underline;
}
/* 结算导航CSS */

/* 主要内容容器CSS END */
.title {
  font-size: 14px;
}

</style>